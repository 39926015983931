import React, { useState, useLayoutEffect } from 'react'

// Images
import arrowRight from 'img/icon/arrow_right.svg'

// Components
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'
import parse from 'html-react-parser'

const StyledEducationItem = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.light};
  position: relative;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

  ${(props) =>
    props.withContrast
      ? css``
      : css`
          max-width: 400px;
        `}
`

const StyledCustomLink = styled(CustomLink)`
  text-decoration: none !important;
`

const Day = styled.p`
  font-size: ${(props) => props.theme.font.size.l};
  color: ${(props) => props.theme.color.text.secondary};
`

const Month = styled.p`
  letter-spacing: 1px;
  color: ${(props) => props.theme.color.text.secondary};
  position: relative;
  top: -5px;
`

const Title = styled.p`
  color: ${(props) => props.theme.color.text.secondary};
  font-weight: ${(props) => props.theme.font.weight.l};
`

const Info = styled.p`
  font-size: ${(props) => props.theme.font.size.sm};

  ${(props) =>
    props.withContrast
      ? css`
          color: ${(props) => props.theme.color.text.contrast};
        `
      : css`
          color: ${(props) => props.theme.color.text.secondary};
        `}
`

const LinkIcon = styled.img`
  transform: rotate(180deg);
  position: absolute;
  bottom: 15px;
  right: 10px;
  width: 49px !important;
`

const EducationItem = ({
  animationDirection,
  morePadding,
  withContrast,
  initialDelay,
  content = {},
  index,
  className,
}) => {
  const [ref, isVisible] = useInView({
    threshold: 0.1,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if (isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  let date = null

  if (content.parsedEvents) {
    date = new Date(content.parsedEvents[0].Datum_sessie)
  }

  return (
    <StyledCustomLink
      className={`d-flex ${
        withContrast
          ? `justify-content-center`
          : `justify-content-center justify-content-lg-start`
      }`}
      to={`/opleidingen/${content?.opleidingTypes?.nodes[0].slug}/${content?.slug}`}
    >
      <StyledEducationItem
        withContrast={withContrast}
        ref={ref}
        className={`w-100 row ${className ? `${className}` : ``}`}
        initial={{ y: 50, opacity: 0 }}
        animate={
          isVisible || view
            ? {
                y: 0,
                opacity: 1,
                transition: {
                  type: 'spring',
                  delay: initialDelay + index * 0.2,
                },
              }
            : { y: 50, opacity: 0 }
        }
        whileHover={
          (animationDirection === 'right' && { x: 20 }) ||
          (animationDirection === 'up' && { y: -10 })
        }
      >
        {date !== null && (
          <div className="col-3">
            <Day className="mb-0 text-center font-weight-xl">
              {date.toLocaleDateString('nl-NL', { day: 'numeric' })}
            </Day>
            <Month className="mb-0 text-center text-uppercase">
              {date.toLocaleDateString('nl-NL', { month: 'long' }).length > 4
                ? date
                    .toLocaleDateString('nl-NL', { month: 'long' })
                    .slice(0, 3)
                : date.toLocaleDateString('nl-NL', { month: 'long' })}
            </Month>
          </div>
        )}
        <div
          className={
            morePadding
              ? `${date !== null ? `col-9 py-2 px-4` : `col-12 py-2 px-4`}`
              : `${date !== null ? `col-9 py-1` : `col-12 py-1`}`
          }
        >
          <Title className={morePadding ? `mb-3` : `mb-1`}>
            {content.title && parse(content.title)}
          </Title>
          <Info withContrast={withContrast} className="mb-0">
            {content.postOpleiding
              ? `${
                  content.postOpleiding.duration
                    ? `${content.postOpleiding.duration} | `
                    : ``
                } ${
                  content.postOpleiding.prijs
                    ? `${new Number(content.postOpleiding.prijs).toLocaleString(
                        'nl-NL',
                        { currency: 'EUR', style: 'currency' }
                      )} excl. BTW`
                    : ``
                }`
              : ``}
          </Info>
        </div>
        {!withContrast && <LinkIcon loading="eager" src={arrowRight} alt="" />}
      </StyledEducationItem>
    </StyledCustomLink>
  )
}

export default EducationItem
