/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'elements/Hero/HeroDefault'
import Breadcrumb from 'elements/Breadcrumb'
import ContentLink from 'elements/ContentLink'
import TopThreeEducations from 'elements/Education/TopThreeEducations'
import ExtraContent from 'elements/ExtraContent'
import Filter from 'elements/Education/Filter'

// Components
import SEO from 'shared/SEO'
import Layout from 'layout/Layout'

// Third Party
import styled from 'styled-components'

const Row = styled.div`
  margin-bottom: -200px;

  @media screen and (max-width: 991px) {
    margin-bottom: 0;
  }
`

const PageTemplate = ({
  data: {
    page: { title, paginaOpleidingen, seo },
  },
  pageContext,
}) => {
  const [isScrollable, setIsScrollable] = useState(true)

  return (
    <Layout isScrollable={isScrollable}>
      <SEO seo={seo} />

      <HeroDefault fields={paginaOpleidingen.paginaopleidingenbanner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <div className="container py-lg-5">
        <Row className="row">
          <div className="col-lg-6">
            <ContentLink
              content={paginaOpleidingen.paginaopleidingenchooseEducation}
            />
          </div>
          <div className="col-lg-6 pb-lg-0 pb-4 pt-lg-0 pt-3 d-flex justify-content-end">
            <TopThreeEducations />
          </div>
        </Row>
      </div>

      <Filter setIsScrollable={setIsScrollable} />

      <ExtraContent content={paginaOpleidingen.paginaopleidingenrelation} />

      <div className="d-md-block d-none" style={{ height: '100px' }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpPage(databaseId: { eq: $wordpress_id }) {
      ...EducationsPageFrag
    }
  }
`

export default PageTemplate
