import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import EducationItem from 'elements/Education/EducationItem'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

const StyledTopThreeEducations = styled(motion.div)`
  background-color: ${(props) => props.theme.color.face.main};
  padding: 20px 30px 20px 30px;
  width: 450px;
  position: relative;
  top: -230px;

  @media screen and (max-width: 991px) {
    top: unset;
  }
`

const Title = styled.h2`
  font-size: ${(props) => props.theme.font.size.l};
  font-weight: ${(props) => props.theme.font.weight.xl};
  color: ${(props) => props.theme.color.text.light};
`

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.font.size.mx};
  font-weight: ${(props) => props.theme.font.weight.m};
  color: ${(props) => props.theme.color.text.contrast};
`

const TopThreeEducations = ({ className }) => {
  const {
    topThree: {
      componentTop3Opleidingen: { educations: topThree },
    },
    allEducations: { nodes: allEducations },
  } = useStaticQuery(graphql`
    {
      topThree: wpComponent(databaseId: { eq: 1148 }) {
        componentTop3Opleidingen {
          educations {
            relation {
              ... on WpOpleiding {
                databaseId
              }
            }
          }
        }
      }

      allEducations: allWpOpleiding {
        nodes {
          title
          uri
          slug
          databaseId

          postOpleiding {
            duration
            prijs
          }

          opleidingTypes {
            nodes {
              slug
            }
          }
        }
      }
    }
  `)

  const realTopThree = []

  topThree.forEach((top) => {
    realTopThree.push(
      allEducations.filter(
        (allE) => allE.databaseId === top.relation[0].databaseId
      )[0]
    )
  })

  return (
    <StyledTopThreeEducations
      initial={{ y: 50 }}
      animate={{ y: 0 }}
      transition={{ delay: 0.4, type: 'spring', damping: 20 }}
      className={`${className ? `${className}` : ``}`}
    >
      <Title>TOP 3</Title>
      <SubTitle className="mb-3">meestgekozen opleidingen</SubTitle>
      {realTopThree.map((i, index) => (
        <EducationItem
          animationDirection="right"
          morePadding
          initialDelay={0.6}
          key={index}
          content={i}
          index={index}
          className="mx-0 my-2 py-2"
        />
      ))}
    </StyledTopThreeEducations>
  )
}

export default TopThreeEducations
